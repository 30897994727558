<template>
  <div @click="toPage">index</div>
</template>

<script>
export default {
  mounted () {
    this.$toast('这是主页');
  },
  methods: {
    toPage() {
      this.$router.push({path:'/active'})
    }
  }
}
</script>

<style>

</style>